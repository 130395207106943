import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useSearchParams } from "react-router-dom";

const Rmamazon = () => {
    const [searchParams] = useSearchParams();

    const utm_source = searchParams.get("utm_source");
    const slider = {
        loop: true,
        margin: 0,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    }
    return (
        <>
            {/* header start */}
            <div className="header">
                <div className="header-top">
                    <div className="header-t-inner">
                        <marquee behavior="alternate">
                            <div className="marq-inner">
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                                <p>Limited Time Deal | COD Available | Free Shipping Across India</p>
                            </div>
                        </marquee>
                    </div>
                </div>
                <div className="header-bottom">
                    <div className="container">
                        <div className="header-b-inner">
                            <div className="header-logo">
                                <img src="assets/images/avimee logo_page-0001.jpg" alt="logo"></img>
                            </div>
                            <div className="contact-btn">
                                <a href="https://avimeeherbal.com/pages/contact" className="action-primary" target="_blank">
                                    CONTACT US
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header end */}
            {/* adv page start */}
            <div className="adv-page">
                <div className="adv-inner">
                    <OwlCarousel {...slider} className='owl-theme' >
                        <div class='item'>
                            <img src="assets/images/rm 1.png" alt="nanaji picture"></img>
                        </div>
                        <div class='item'>
                            <img src="assets/images/rm 2.png" alt="nanaji picture"></img>
                        </div>
                    </OwlCarousel>
                </div>
            </div>
            {/* adv page end */}
            <div className="amazon-btn">
                <a
                    href={`https://www.amazon.in/stores/page/5DFCCB07-B664-494F-B1F3-737866DDAB97?channel=${utm_source}`}
                    target="_blank"
                ><img src="assets/images/b.png"></img></a>
            </div>
            {/* review block start */}
            <div className="review-block">
                <div className="container">
                    <div className="review-inner">
                        <div className="section-title">
                            <h2>Customer Reviews</h2>
                        </div>
                        <div className="review-item">
                            <div className="review-i-inner">
                                <img src="assets/images/Rectangle 8(3).png" alt="free shipping"></img>
                            </div>
                            <div className="review-i-inner">
                                <img src="assets/images/Rectangle 9(3).png" alt="amazon prime"></img>
                            </div>
                            <div className="review-i-inner">
                                <img src="assets/images/Rectangle 10(3).png" alt="online support"></img>
                            </div>
                            <div className="review-i-inner">
                                <img src="assets/images/Rectangle 11(3).png" alt="pay on delivery"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* review block end */}
            {/* st blog start */}
            <div className="st-blog">
                <img src="assets/images/Rectangle 12.png" alt="shark-tank"></img>
            </div>
            {/* st blog end */}
            {/* service block start */}
            <div className="service-block">
                <div className="container">
                    <div className="service-inner">
                        <div className="service-item">
                            <div className="service-img">
                                <img src="assets/images/free.png" alt="free"></img>
                            </div>
                            <div className="service-text">
                                <p>Free Shipping</p>
                                <span>The product is eligible
                                    <br />for Free delivery.</span>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-img">
                                <img src="assets/images/amazon-prime.png" alt="amazon-prime"></img>
                            </div>
                            <div className="service-text">
                                <p>Amazon Prime</p>
                                <span>Assured Fast Delivery</span>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-img">
                                <img src="assets/images/online-chat.png" alt="online-chat"></img>
                            </div>
                            <div className="service-text">
                                <p>Online Support</p>
                                <span>Get Free Consultation
                                    <br />From Our Doctors</span>
                            </div>
                        </div>
                        <div className="service-item">
                            <div className="service-img">
                                <img src="assets/images/cash-on-delivery .png" alt="cash-on-delivery"></img>
                            </div>
                            <div className="service-text">
                                <p>Pay On Delivery</p>
                                <span>Pay At Your
                                    <br />convenience</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* service block end */}
            <div className="contact-bt">
                <a href="https://avimeeherbal.com/pages/contact" className="action-primary" target="_blank">
                    CONTACT US
                </a>
            </div>
            {/* story blog start */}
            <div className="story-blog">
                <a href="https://avimeeherbal.com/blogs/press-coverage" target="_blank">
                    <img src="assets/images/Rectangle 13.png" alt="story"></img>
                </a>
            </div>
            {/* story blog end */}
        </>
    );
};
export default Rmamazon;