import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Avime from './Avime';
import Hgamazon from './Hgamazon';
import Pvamazon from './Pvamazon';
import Rmamazon from './Rmamazon';
import Skamazon from './Skamazon';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Avime/>} />
            <Route path="/hgamazon" element={<Hgamazon/>} />
            <Route path="/pvamazon" element={<Pvamazon/>} />
            <Route path="/rmamazon" element={<Rmamazon/>} />
            <Route path="/skamazon" element={<Skamazon/>} />
        </Routes>
    </BrowserRouter>);

